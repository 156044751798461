import React from 'react';
import './About.css'; // Importa los estilos específicos de About

function About() {
  return (
    <section id="sobre-nosotros" className="about-section">
      <h2>Sobre Nosotros</h2>
      <div className="about-grid">
        <div className="about-item">
          <h3>Nuestra Misión</h3>
          <p>
            Proporcionar soluciones web innovadoras y de alta calidad que ayuden a nuestros clientes a alcanzar sus metas.
          </p>
        </div>
        <div className="about-item">
          <h3>Visión</h3>
          <p>
            Ser líderes en el desarrollo de soluciones tecnológicas que transformen el entorno digital.
          </p>
        </div>
        <div className="about-item">
          <h3>Nuestros Valores</h3>
          <p>
            Innovación, calidad, compromiso y responsabilidad son los pilares que nos guían.
          </p>
        </div>
        <div className="about-item">
          <h3>Nuestro Equipo</h3>
          <p>
            Contamos con un equipo multidisciplinario de expertos en desarrollo web, diseño gráfico, marketing digital y optimización SEO.
          </p>
        </div>
        <div className="about-item">
          <h3>Nuestra Historia</h3>
          <p>
            Desde nuestros inicios, hemos trabajado con empresas de todos los tamaños, ayudándolas a crecer en el entorno digital.
          </p>
        </div>
        <div className="about-item">
          <h3>Contacto</h3>
          <p>
            Siempre estamos aquí para ayudarte. No dudes en ponerte en contacto con nosotros para cualquier consulta o proyecto.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
