import React from 'react';
import './App.css';
import './scrollHeader';
import Header from './Header';
import Services from './Services';
import Portfolio from './Portfolio';
import About from './About';
import Contact from './Contact';
import Testimonials from './Testimonials';
import Footer from './Footer';
import Home from './Home'; // Importa el nuevo componente Home
import ScrollToTopButton from './ScrollToTopButton';
import { app } from './firebaseConfig'; // Importa app como una exportación nombrada

function App() {
  return (
    <div className="flex flex-col min-h-screen relative">
      <ScrollToTopButton />
      <div className="relative z-10">
        <Header />
        <main className="flex-1">
          <Home /> {/* Utiliza el componente Home aquí */}
          <section id="servicios" className="py-12">
            <Services />
          </section>
          <section id="portafolio" className="py-12">
            <Portfolio />
          </section>
          <section id="sobre-nosotros" className="py-12">
            <About />
          </section>
          <section id="testimonios" className="py-12">
            <Testimonials />
          </section>
          <section id="contacto" className="py-12">
            <Contact />
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const appInstance = app; // Asegúrate de que esta línea esté presente si necesitas inicializar Firebase aquí.

export default App;
