import React from 'react';
import './Portfolio.css'; // Importa los estilos específicos de Portfolio

import projectImage1 from './assets/project1.png';
import projectImage2 from './assets/project2.png';
import projectImage3 from './assets/project3.png';
import projectImage4 from './assets/project4.png';
import projectImage5 from './assets/project5.png';
import projectImage6 from './assets/project6.png';

function Portfolio() {
  return (
    <section id="portafolio" className="portfolio-section">
      <h2>Nuestro Portafolio</h2>
      <div className="portfolio-grid">
        <div className="portfolio-item">
          <img src={projectImage1} alt="Proyecto 1" className="portfolio-image" />
        </div>
        <div className="portfolio-item">
          <img src={projectImage2} alt="Proyecto 2" className="portfolio-image" />
        </div>
        <div className="portfolio-item">
          <img src={projectImage3} alt="Proyecto 3" className="portfolio-image" />
        </div>
        <div className="portfolio-item">
          <img src={projectImage4} alt="Proyecto 4" className="portfolio-image" />
        </div>
        <div className="portfolio-item">
          <img src={projectImage5} alt="Proyecto 5" className="portfolio-image" />
        </div>
        <div className="portfolio-item">
          <img src={projectImage6} alt="Proyecto 6" className="portfolio-image" />
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
