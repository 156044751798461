import React from 'react';
import Person1 from './assets/person1.jpg';
import Person2 from './assets/person2.jpg';
import Person3 from './assets/person3.jpg';
import Person4 from './assets/person4.jpg';

function Testimonials() {
  return (
    <section id="testimonios" className="bg-white py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold" style={{ color: 'var(--accent-color)' }}>Lo que dicen nuestros clientes</h2>
        <p className="mt-4 text-lg" style={{ color: '#000000' }}>Descubre cómo hemos ayudado a otros a alcanzar sus objetivos</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="bg-gray-100 p-6 rounded shadow">
            <p className="text-black">"El equipo de The 18s Technologies hizo un excelente trabajo en el desarrollo de nuestro sitio web. Estamos muy satisfechos con el resultado final y la atención personalizada que recibimos durante todo el proceso."</p>
            <div className="mt-4 flex items-center space-x-4">
              <img src={Person1} alt="Cliente 1" className="w-12 h-12 rounded-full" />
              <div>
                <p className="font-bold">John Doe</p>
                <p className="text-gray-600">CEO, Empresa ABC</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 p-6 rounded shadow">
            <p className="text-black">"Trabajar con The 18s Technologies ha sido una experiencia increíble. Su equipo de expertos nos guió a lo largo de todo el proceso y el resultado final superó nuestras expectativas."</p>
            <div className="mt-4 flex items-center space-x-4">
              <img src={Person2} alt="Cliente 2" className="w-12 h-12 rounded-full" />
              <div>
                <p className="font-bold">Sarah Miller</p>
                <p className="text-gray-600">Gerente de Marketing, Empresa XYZ</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 p-6 rounded shadow">
            <p className="text-black">"The 18s Technologies nos ayudó a mejorar nuestra presencia en línea de una manera que no creíamos posible. Su profesionalismo y dedicación son inigualables."</p>
            <div className="mt-4 flex items-center space-x-4">
              <img src={Person3} alt="Cliente 3" className="w-12 h-12 rounded-full" />
              <div>
                <p className="font-bold">Carlos Pérez</p>
                <p className="text-gray-600">Director, Empresa DEF</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 p-6 rounded shadow">
            <p className="text-black">"Gracias a The 18s Technologies, hemos visto un aumento significativo en el tráfico de nuestro sitio web. ¡Altamente recomendados!"</p>
            <div className="mt-4 flex items-center space-x-4">
              <img src={Person4} alt="Cliente 4" className="w-12 h-12 rounded-full" />
              <div>
                <p className="font-bold">Laura Gómez</p>
                <p className="text-gray-600">Fundadora, Startup GHI</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
